.selectionAndInterviewPanel__container {
  width: 75%;
  font-family: 'Lato', sans-serif;
  text-align: center;
  margin: auto;
}

.selectionAndInterviewPanel__heading{
  font-size: 25px;
  line-height: 40px;
  padding: 50px;
}

.selectionAndInterviewPanel__column {
  margin-bottom: 16px;
  padding: 0 8px;
  flex: 33.33%;
}

/* Display the columns below each other instead of side by side on small screens */
@media screen and (min-width: 650px) {
  .selectionAndInterviewPanel__row {
    display: flex;
  }

  .selectionAndInterviewPanel__heading{
    font-size: 32px;
  }
}