.diagram-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
    margin: 0 auto;
  }
  
  .circle-container {
    display: flex;
    align-items: center;
  }
  
  .circle {
    width: 18vw; /* Responsive width based on viewport */
    height: 18vw; /* Responsive height based on viewport */
    max-width: 150px;
    max-height: 150px;
    background-color: #95DAC9;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    border: 4px solid white;
    padding: 10px;
    font-size: 1.2vw; /* Responsive font size based on viewport */
    position: relative;
  }
  
  .line {
    width: 8vw; /* Responsive line width based on viewport */
    height: 4px;
    background-color: black;
  }
  
  .circle-container:last-child .line {
    display: none;
  }
  
  /* Mobile view - stack circles vertically and center the connecting line */
@media (max-width: 768px) {
    .diagram-container {
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }
  
    .circle-container {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  
    .circle {
      width: 150px;
      height: 150px;
      font-size: 14px;
    }
  
    .line {
      width: 4px; /* Narrower line */
      height: 40px; /* Taller line */
    }
  }