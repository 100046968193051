.page--timeline{
	background-color: $dark-bluish-green;
	color: white;
	padding: 90px 0px;
	font-size: 16px;
}

.timeline{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	opacity: 0;
	animation: fade-in 0.6s linear;
	animation-fill-mode: forwards;
	padding: 10px 10px;
}

@keyframes fade-in {
	0% {
		opacity: 0%;
		transform: translateY(-10px);
	}
	100% {
		opacity: 100%;
		transform: translateY(0px);
	}
}

.timeline--header{
	color: $glowing-green;
}

.timeline > h2{
	font-weight: 400;
	margin-bottom: 15px;
}

.page__italic-text--timeline{
	color: $light-black;
	font-size: 18px;
}