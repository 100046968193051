.page{
	font-family: 'Lato', sans-serif;
	font-size: 17px;
	font-weight: 400;
	line-height: 30px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}

.page__container{
	padding: 70px 0;
	margin: 0 auto;
}

.page__list{
	padding-inline-start: 0;
	padding-left: 3em;
}

.page__list--inner{
	list-style: disc outside;
}

.page__link{
	color: $orange-beige-color;
	text-decoration: none;
}

.page__link:hover{
	color: $light-grey;
}

.page__light-text{
	font-weight: 400;
	color: $grey;
}

.page__italic-text{
	font-style: italic;
}

.page__note{
	color: $light-red;
	font-weight: 600;
}

.page__step-header{
	color: $mine-shaft;
	font-weight: 600;
	padding: 10px 0 10px 0;
}
.page__align-container{ // used with stuff to be center aligned
	display: flex;
	align-items: center;
	justify-content: center;
}
.page__link--highlighted{
	font-size: 32px;
	text-decoration: underline;
}

.page__center-text{
	justify-content: center;
	align-items: center;
	text-align: center;
}

@media screen and (max-width: $desktop-breakpoint) {
	.page__list{
		padding-left: 1em;
	}
}