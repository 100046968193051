.stakeholderWithImage__container{
    width: 204px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 2px;
    margin: 20px auto;
    box-shadow: none;
    transition: border 300ms ease-in-out, box-shadow 300ms ease-in-out;
}

/* Clear floats */
.stakeholderWithImage__container::after, .row::after {
    content: "";
    clear: both;
    display: table;
}

.stakeholderWithImage__image{
    margin: 0;
    border-radius: 0;
    overflow: hidden;
    height: 204px;
    width: 202px;
}