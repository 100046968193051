.home__container{
	position: relative;
	padding: 20px 0;
	margin: 0 auto;
}

.home__container--with-image{
	display: flex;
	flex-direction: row;
}

.home__container__bg{
	position: relative;
}

.home__margin-text--heading{
	text-align: center;
}

.home__container__bg::before{
	content: "";
	position: absolute;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	// width: 250vw;
	width: 100.55vw; // trial and error and binary search
	left: -14vw;
	top: -70px;
	height: calc(100% + 70px);
	z-index: -1;
}
.home__container__bg--1{
	position: relative;
}
.home__container__bg--1::before{
	content: "";
	background: url('../../assets/BackgroundImages/BGI_1.jpg') no-repeat;
}

.home__container__bg--2::before{
	content: "";
	background-image: none;
	z-index: -2;
}
.home__container__bg--3::before{
	content: "";
	background: url('../../assets/BackgroundImages/BGI_2.jpg');
	// height: 100%;
	top: 0;
	z-index: -5;
}

.home__container__bg--4::before{
	content: "";
	background-color: $light-grey;
	top: 0;
}

.home__container__bg--5{
	color: white
}

.home__container__bg--5::before{
	content: "";
	background-color: $greyish-green;
	top: 0;
}
.home__container__bg--6::before{
	content: "";
	background-color: $green;
	top: 0;
	height: 100%;
}

.home__des{
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background-color: $green;
	color: white;
}

.home__header--cont-2{
	color: $home-grey-1;
}

.home__header--cont-3{
	color: $mine-shaft;
	font-size: 45px;
	line-height: 40px;
}

.home__image{
	width: 100%;
	height: auto;
}

.home__left-cont{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: justify;
	padding: 0 25px;
}

.home__header--cont-5{
	width: 100%; 
	text-align: center;
}

.home__header--cont-5::after{
	content: "";
}

.home__right-cont{
	z-index: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.home__learn-more{
	background-color: $learn-more-grey;
	color: white;
	cursor: pointer;
	padding: 10px 25px;
	border: none;
	border-radius: 2px;
	transition: 0.3s;
	font-size: 15px;
}

.home__learn-more:hover{
	background-color: darken($learn-more-grey, 10%);
}

.home__left-cont--report{
	color: white;	
	text-align: right;
	font-size: 30px;
	width: auto;
	text-align: center;
}

.home__report{
	width: 25rem;
}

.home__calendar-div{
	width: 60%;
}

.home__header--cont-7{
	color: $mine-shaft;
	font-size: 45px;
	line-height: 40px;
	text-align: center;
}

@media screen and (max-width:767px) { .fc-toolbar.fc-header-toolbar {font-size: 60%}}

@media screen and (max-width: $desktop-breakpoint){
	.home__container{
		padding-bottom: 60px;
	}
	.home__container__bg::before{
		width: 100vw;
		left: -3vw;
	}
	.home__des{
		padding: 0 15px;
	}

	.home__right-cont{
		margin: 25px;
	}
}

@media screen and (max-width: $tablet-breakpoint){
	.home__container--with-image{
		flex-direction: column;
		padding-left: 5px;
		padding-right: 20px;
	}
	.home__header--cont-3{
		font-size: 25px;
		line-height: 20px;
	}
	.home__header--cont-7{
		font-size: 25px;
		line-height: 20px;
	}
	.home__left-cont{
		padding: 0;
	}
	.home__left-cont--report{
		font-size: 25px;	
	}

	.home__margin-text{
		margin: 15px;
	}
	
	.home__learn-more-div{
		display: flex;
		justify-content: center;
	}

	.home__calendar-div{
		width: 90%;
	}
}

@media screen and (max-width: $mobile-breakpoint){
	.home__des{
		align-items: flex-start;
	}
}