.ReactModalPortal{
	position: absolute;
	z-index: 10;
	text-align: center;
	justify-content: space-between;
	height: 1px;
	width: 1px;
}
.ReactModalPortal .ReactModal__Overlay {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
}
._modal{
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white;
	max-width: 60rem;
	border: none;
	margin: auto;
	border-radius: 15px;
	color: $green;
	font-weight: bold;
	border: 5px solid lighten($glowing-green, 20%);
}

.calendar-modal{
	width: 40vw;
	height: auto;
	max-height: 50vw;
	background-color: white;
	display: flex;
	flex-direction: column;
	position: relative;
	background-color: $light-light-grey;
	border-radius: 10px;
	align-items: center;

}

.calendar-modal__content-div{
	position: relative;
	width: 97%;
	overflow-y: auto;
	text-align: left;
	padding: 10px;
}

.calendar-modal__close-button{
	position: absolute;
	right: 5px;
	top: 5px;
	background: none;
	border: none;
	padding: 0;
	width: 2rem;
	height: 2rem;
}

.calendar-modal__close-button:hover{
	cursor: pointer;
}

.button-image{
	width: 50%;
}

@media screen and (max-width: $tablet-breakpoint){
	.calendar-modal{
		width: 80vw;
		height: auto;
		max-height: 80vh;
	}
}