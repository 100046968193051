.footer {
	box-sizing: border-box;
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: rgb(255, 255, 255);
	color: rgb(30, 83, 91);
	flex-direction: column;
	align-items: flex-end;
	padding: 10px 10px 10px 10px;
}

.footer-subdiv {
	display: flex;
	padding: 10px 10px 10px 10px;
	font-family: 'Lato', sans-serif;
	font-size: 10px;
	display: flex;
	font-weight: 400;
	line-height: 15px;
	color: #888888;
}
