.header {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: rgb(30, 83, 91);
    color: rgb(30, 83, 91);
    align-items: center;
    flex-direction: column;
  }
  
.logo {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
	width: 50%;
}

@media screen and (max-width: 1080px) {
	.logo{
		width: 60%;
	}
}

@media screen and (max-width: 768px) {
	.logo{
		width: 95%;
	}
}