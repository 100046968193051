.campusAmb__container-title {
	text-align: center;
	margin-bottom: 10px;
	margin-top: 10px;
	line-height: 50px;
	font-size: 40px;
}

.campusAmb__container-subtitle {
	text-align: center;
	padding: 10px;
	line-height: 30px;
	font-size: 25px;
}

.campusAmb__container-text {
	text-align: left;
	padding: 10px;
}