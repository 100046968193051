// All the constants go here

$desktop-breakpoint: 1080px;
$tablet-breakpoint: 768px;
$mobile-breakpoint: 425px;

$orange-beige-color: #c2743b;

$light-grey: #aaaaaa;
$grey: #888888;
$light-black: #999999;
$mine-shaft: #333333;
$dark-grey: #626262;
$home-grey-1: #d5d5d5;
$learn-more-grey: rgb(196, 196, 196);
$light-light-grey: #f6f6f6;

$green: rgb(30, 83, 91);
$orange: #da664f;
$dark-bluish-green: #0c1827;
$glowing-green: #54c0a6;
$light-red: #c23b3b;
$greyish-green: #323d3f;
$yellow: #fffd9e;