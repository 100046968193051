.leadership__leader{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
}

.leadership__people{
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
}

.leadership__header{
	font-size: 30px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-grow: 1;;
}
.leadership__description{
	padding: 15px;
	text-align: justify;
	margin: 18px;
}

.leadership__group{
	margin-bottom: 5rem;
}

.leadership__header::before{
	content: "";
	display: "block";
	width: 25%;
	height: 4px;
	background-color: lighten($home-grey-1, 10%);
	border-radius: 10px;
	left: 0;
	top: 50%;
	position: relative;
}

.leadership__header::after{
	content: "";
	display: "block";
	width: 25%;
	height: 4px;
	border-radius: 10px;
	background-color: lighten($home-grey-1, 10%);
	border-left: 40px solid lighten($home-grey-1, 10%);
	right: 0;
	top: 50%;
	position: relative;
}


@media screen and (max-width: $mobile-breakpoint) {
	.leadership__leader{
		flex-direction: column;
	}
	.leadership__header::after{
		width: 0;
	}
	.leadership__header::before{
		width: 0;
	}
}

@media screen and (max-width: $tablet-breakpoint) {
	.leadership__people{
		flex-direction: column;

	}
}