.requirements__page {
	font-weight: 400;
	color: $grey;
	margin-top: 20px;
	padding: 40px 0;
}

.requirements__container {
	background-color: $home-grey-1;
	margin-top: 20px;
	padding: 40px 0;
}

.requirements__container-title {
	text-align: center;
	margin-bottom: 10px;
	margin-top: 10px;
	line-height: 40px;
	font-size: 30px;
}

.requirements__container-text {
	margin-left: 30px;
	margin-right: 30px;
	text-align: left;
	padding: 10px;
}

.requirements__essays {
	padding: 40px 0;
}

.requirements__essays-img {
	display: flex;
	flex-direction: row;
	align-content: space-between;
}

.requirements__img-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 5px 0;
	text-align: justify;
	width: 33%;
	margin: 10px;
}

.requirements__img {
	width: 100%;
	height: auto;
}

.requirements__container--brownie{
	background-color: $orange;
	color: white;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.requirements__brownie-img{
	filter: invert(1);
	width: 100%;
	height: 100%;
}

@media screen and (max-width: $tablet-breakpoint){
	.requirements__essays-img{
		flex-direction: column;
	}

	.requirements__img-container{
		width: 95%;
	}
	
	.requirements__container--brownie{
		flex-direction: column;
	}
}