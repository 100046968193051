.wwu__image-container {
	display: flex;
	justify-content: space-between;
	padding-top: 30px;
}

.wwu__left-container {
	display: flex;
	font-size: 23px;
	align-items: center;
}

.wws__volunteer-header {
	font-size: 30px;
	text-align: center;
	padding: 20px;
}

.wwu__font-black {
	color: black;
}

.wwu__font-orange {
	color: $orange;
	font-weight: bold;
}

.wwu__list-container {
	margin-left: 50px;
	list-style-type: disc;
	padding: 10px 0 10px 0;
}

.divider {
	border: 1px solid $home-grey-1;
}

@media screen and (max-width: $mobile-breakpoint) {
	.wwu__image-container {
		flex-wrap: wrap;
	}
	.wwu__img {
		width: 300px;
		height: 80px;
	}
}
