.finalists__container {
    font-family: 'Lato', sans-serif;
    text-align: center;
    margin: auto;
}
  
.finalists__heading{
    font-size: 25px;
    line-height: 40px;
    padding: 50px;
}
  
.finalists__column {
    margin-bottom: 16px;
    padding: 0 8px;
    flex: 33.33%;
}

.finalists__row{
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.finalists__period{
	font-family: 'Lato', sans-serif;
	font-size: 20px;
	font-weight: 600;
	padding: 30px 20px;
	text-align: left;
}
    /* Display the columns below each other instead of side by side on small screens */
@media screen and (max-width: 900px) {
	.finalists__row {
		flex-wrap: wrap;
	}

	.finalists__heading{
		font-size: 32px;
	}
	.finalists__period{
		text-align: center;
	}
}

@media screen and (max-width: 768px) {
	.finalists__row{
		flex-direction: column;
	}
}