.slideshow{
	display: block;
	z-index: -1;
	margin-left: auto;
	margin-right: auto;

	.indicator {
		cursor: pointer;
		padding: 10px;
		text-align: center;
		border: 1px #666 solid;
		margin-right: 5px;
	}

	.indicator.active {
		color: #fff;
		background: rgb(196, 196, 196);
	}
}

.slideshow__image{
	width: 100%;
	height: 100%;
}

.slideshow__each-fade{
	z-index: -1;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0px 40px;
}

.slideshow__image-container{
	display: flex;
	flex-direction: column;
	text-align: center;
}

@media screen and (max-width: $tablet-breakpoint) {
	.slideshow{
		width: 80vw;
	}
}