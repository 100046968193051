.stakeholderImageCard__container{
    width: 204px;
    height: 100%;
    box-sizing: border-box;
    border-radius: 2px;
    margin: 20px auto;
    box-shadow: none;
    transition: border 300ms ease-in-out, box-shadow 300ms ease-in-out;
}

/* Clear floats */
.stakeholderImageCard__container::after, .row::after {
    content: "";
    clear: both;
    display: table;
}

.stakeholderImageCard__image{
    margin: 0;
    border-radius: 0;
    overflow: hidden;
    height: 204px;
    width: 202px;
}

.stakeholderImageCard__card{
    padding: 20px 0 10px;
    margin: 0 20px;
    text-align: center !important;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
    background-color: #FFFFFF;
}

.stakeholderImageCard__card_name{
    color: #000000;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    display:block;
    margin-bottom: 8px;
}

.stakeholderImageCard__card_pronouns{
    color: #888888;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    display: inline;
    font-style: italic;
}

.stakeholderImageCard__card_designation{
    font-size: 12px;
    color: rgba(75,81,85,0.6);
    text-transform: uppercase;
    padding: 5px 0;
}

.stakeholderImageCard__card_education{
    font-size: 12px;
    color: rgba(75,81,85,0.6);
    font-style: italic;
    padding: 5px 0;
}