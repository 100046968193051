.objectives__page-div{
	display: flex;
	position: relative;
	flex-direction: row;
	text-align:center;
	align-items: center;
}
.objectives__image{
	height: 20%;
	width: 20%;
}
.objectives__text-box{
	color: white;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 40px 20px;
}
.objectives__text-box::before{
	content:"";
    background-color: $orange;
    position: absolute;
	width: 100%;
	height: 100%;
    z-index: -1;
}
.objectives__title{
	text-align:center;
	margin-bottom: 20px;
	margin-top: 20px;
	line-height: 40px;
	font-size: 45px;
}
.objectives__text{
	padding: 5px;
}
.objectives__mv-text-box{
	color: white;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 40px 20px;
}
.objectives__mv-text-box::before{
	content:"";
    background-color: $home-grey-1;
    position: absolute;
	width: 100%;
	height: 100%;
    z-index: -1;
}
.objectives__mv-title{
	text-align:center;
	color: #000000;
	margin-bottom: 20px;
	margin-top: 20px;
	font-size: 25px;
	padding: 5px;
}
.objectives__mv-text{
	text-align:center;
	font-style: italic;
	color: $dark-grey;
	margin-bottom: 20px;
	margin-top: 20px;
	font-size: 25px;
	padding: 5px;
}

@media only screen and (max-width: 768px) {
	.objectives__title{
		font-size: 24px;
	}
}