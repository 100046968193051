.testimonial{
	background-color: white;
	padding: 3rem;
}

.testimonial__message{
	font-weight: bold;
	font-size: 22px;
}
.testimonial__by{
	font-size: 15px;
	margin-top: 2rem;
}