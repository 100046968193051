.gs__header {
	font-weight: 400;
	font-size: 40px;
	color: $mine-shaft;
}

.gs__image-container {
	display: flex;
	justify-content: space-between;
	padding-top: 30px;
}

.gs__right-container {
	padding-left: 40px;
}

.gs__table-container,
.gs__qr-code-img-container {
	display: flex;
	justify-content: center;
}

.gs__table,
th,
td {
	border: 1px solid black;
	border-collapse: collapse;
	text-align: center;
	padding: 10px 50px;
}

.gs__table {
	margin: 50px 0px 20px 0px;
}

.gs__img-qr-code {
	width: 200px;
}

ol li::marker {
	color: $light-black;
}

@media screen and (max-width: $tablet-breakpoint) {
	.gs__image-container {
		flex-direction: column;
	}
	.gs__right-container {
		padding: 20px 0 0 0;
	}
	.gs__img {
		width: 100%;
	}
}

@media screen and (max-width: $mobile-breakpoint) {
	.gs__header {
		line-height: 45px;
	}
	.gs__table,
	th,
	td {
		padding: 10px 10px;
	}
}
