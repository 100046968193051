.commitment__orange-layout{
	color: white;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 70px 15%;
}

.commitment__orange-layout::before{
	content:"";
    background-color: $orange;
    position: absolute;
	width: 100%;
	height: 100%;
    z-index: -1;
}

.commitment__box{
	border: 4px solid white;
	width: 100%;
	text-align: center;
	padding: 20px 20px;
	font-size: 24px;
	line-height: 30px;
}


.commitment__title{
	font-style: italic;
	margin: 0;
	margin-bottom: 60px;
	line-height: 40px;
	font-size: 45px;
}


@media only screen and (max-width: 768px) {
	.commitment__title{
		font-size: 24px;
	}
	.commitment__orange-layout{
		padding: 10% 5%;
	}
}