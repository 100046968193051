.ticker{
	background-color: $yellow;
	position: sticky !important;
	color: $green;
	font-size: large;
	padding: 5px 0px;
	z-index: 10;
	top: 60px;
}

.ticker__div{
	padding: 0px 30px;
}

@media screen and (max-width: $tablet-breakpoint) {
	.ticker{
		top: 0px;
	}
}