.stakeholder__container {
    font-family: 'Lato', sans-serif;
    display: flex;
    align-items: center;
	padding: 5px 20px;
	justify-content: space-between;
  }

  .stakeholder__name {
    font-family: 'Lato', sans-serif;
    text-align: left;
    font-size: large;
  }

  .stakeholder__designation {
    font-family: 'Lato', sans-serif;
    font-size: medium;
	font-style: italic;
	text-align: left;
    color: rgba(75,81,85,0.6);
  }

.stakeholder__info{
	display: flex;
	flex-wrap: wrap;
	align-items: baseline;
}
.stakeholder__link{
	display: flex;
}