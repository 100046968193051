.organisers__container {
    width: 75%;
    font-family: 'Lato', sans-serif;
    margin: auto;
  }
  
  .organisers__heading{
    font-size: 25px;
    text-align: left;
    margin-top: 50px;
  }
  
  .organisers__column {
    margin-bottom: 16px;
    align-items: flex-start;
  }
  
/* Display the columns below each other instead of side by side on small screens */
@media screen and (min-width: 650px) {
    .organisers__row {
    display: flex;
    }

    .organisers__heading{
    font-size: 32px;
    }
}