.mentors__container {
    font-family: 'Lato', sans-serif;
    text-align: center;
    margin: auto;
  }
  
  .mentors__heading{
    font-size: 25px;
    line-height: 40px;
    padding: 50px;
  }
  
  .mentors__column {
    margin-bottom: 16px;
    padding: 0 8px;
    flex: 33.33%;
  }

.mentors__row{
	flex-direction: row;
}

.mentors__intro{
	text-align: left;
	line-height: 30px;
}

.mentors__period{
	font-family: 'Lato', sans-serif;
	font-size: 20px;
	font-weight: 600;
	padding: 30px 20px;
	text-align: left;
}
    /* Display the columns below each other instead of side by side on small screens */
    @media screen and (min-width: 900px) {
      .mentors__row {
        display: flex;
		flex-wrap: wrap;
      }
  
      .mentors__heading{
        font-size: 32px;
      }
    }