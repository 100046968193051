.app{
	box-sizing: border-box;
}

.navbar {
	position: sticky;
	top: 0;
    display: flex;
    flex-direction: row;
	width: 100%;
	justify-content: center;
    background-color: #14525a;
	z-index: 15;
  }
  
.nav-menu {
    display: flex;
	flex-direction: row;
	justify-content: flex-end;
    margin: 0;
	max-height: 60px;
}

.nav-dropdown {
    position: relative;
	display: flex;
	flex-direction: column;
}

.nav-dropdown:hover .nav-dropdown-content {
    display: block;
}
.nav-dropdown-content {
    display: none;
    position: absolute;
    z-index: 1;
    background-color: rgb(30, 83, 91);
}

.nav-item {
    display: flex;
    align-items: center;
	position: relative;
	z-index: 2;
}

.nav-links {
    color: white;
    text-decoration: none;
    padding: 1.2rem 0.8rem;
	display: flex;
	justify-content: space-between;
}

.nav-links:hover {
    background-color: rgb(23, 67, 73);
    transition: all 0.2s ease-out;
}

.nav-dropdown-item{
	display: flex;
}


.dropdown{
	display: flex;
	width: max-content;
	position: absolute;
	top: 100%;
	flex-direction: column;
	background-color: rgb(30, 83, 91);
}

.dropDownItem {
	display: block;
	background-color: rgb(30, 83, 91);
	padding: 0;
}

.dropdown-link{
	padding: 0.8rem;
}

.dropDownArrow{
	align-self: flex-end;
	font-size: 20px;
	padding: 0;
	height: 21px;
	display: flex;
	align-items: center;

}
.nav-sandwich{
	position: absolute;
	right: 10px;
	top: 10px;
}
.hamburger-container{
	display: inline-block;
  	cursor: pointer;
}

.bar1, .bar2, .bar3 {
	width: 24px;
	height: 4px;
	background-color: white;
	margin: 6px 0;
	transition: 0.4s;
}

.change .bar1 {
	transform: rotate(-45deg) translate(-7px, 4px);
}
  
.change .bar2 {opacity: 0;}
  
.change .bar3 {
	transform: rotate(45deg) translate(-10px, -8px);
}

@media screen and (max-width: 768px) {
	.nav-menu{
		flex-direction: column;
		max-height: none;
	}

	.nav-item{
		justify-content: center;
		flex-direction: column;
	}

	.nav-links{
		justify-content: center;
		padding: 0.5rem;
	}

	.dropdown{
		position: relative;
		top: 0%;
		width: 100%;
	}

	.nav-links--mobile{
		width: 100%;
	}

	.dropDownArrow{
		position: absolute;
		right: 10px;
		transform: rotateZ(90deg);
	}
	.navbar{
		position: relative;
	}
}