.midyearcheckpoint__container {
	padding: 70px 0;
}

.midyearcheckpoint__deadline {
	font-size: 40px;
	text-align: center;
	color: $mine-shaft;
}

.midyearcheckpoint__step-des {
	font-size: 16px;
}

@media only screen and (max-width: $desktop-breakpoint) {
	.midyearcheckpoint__container {
		padding: 40px 0;
	}
}