.plagiarism__container-title {
	margin-bottom: 10px;
	margin-top: 10px;
	line-height: 80px;
	font-size: 40px;
}

.plagiarism__container-text {
	text-align: left;
	padding: 10px;
}